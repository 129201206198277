// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "Details-module--background-missing-image--aa012";
export var cardHeader = "Details-module--card-header--dfd7e";
export var cardImage = "Details-module--card-image--710bb";
export var cardsWrapper = "Details-module--cards-wrapper--43fec";
export var departing = "Details-module--departing--a2093";
export var detailsBanner = "Details-module--details-banner--152e3";
export var detailsContainer = "Details-module--details-container--7cf95";
export var detailsWrapper = "Details-module--details-wrapper--2fdc0";
export var fullWidthCard = "Details-module--full-width-card--fa7d7";
export var gallery = "Details-module--gallery--756da";
export var galleryImages = "Details-module--gallery-images--9769a";
export var include = "Details-module--include--4b733";
export var includeDetails = "Details-module--include-details--b390b";
export var info = "Details-module--info--5560b";
export var infoContainer = "Details-module--infoContainer--5712d";
export var infoDetails = "Details-module--info-details--09849";
export var notInclude = "Details-module--not-include--c4b35";
export var notIncludeDetails = "Details-module--not-include-details--f8da6";
export var returning = "Details-module--returning--04e56";
export var schedule = "Details-module--schedule--08fd6";
export var scheduleDetails = "Details-module--schedule-details--72d92";
export var tags = "Details-module--tags--3dba4";