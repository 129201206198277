// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "ShoppingOptions-module--background-missing-image--1bdf4";
export var departing = "ShoppingOptions-module--departing--a309c";
export var descriptions = "ShoppingOptions-module--descriptions--ca1fa";
export var destination = "ShoppingOptions-module--destination--42135";
export var installmentBar = "ShoppingOptions-module--installment-bar--864fd";
export var loadMore = "ShoppingOptions-module--load-more--8e421";
export var shoppingCard = "ShoppingOptions-module--shopping-card--79da1";
export var shoppingCardBody = "ShoppingOptions-module--shopping-card-body--e93de";
export var shoppingCardHeader = "ShoppingOptions-module--shopping-card-header--6dae4";
export var shoppingCardsWrapper = "ShoppingOptions-module--shopping-cards-wrapper--2622b";
export var shoppingOptionsContainer = "ShoppingOptions-module--shopping-options-container--bb35e";