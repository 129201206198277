// extracted by mini-css-extract-plugin
export var active = "ServicesDetails-module--active--80250";
export var backgroundMissingImage = "ServicesDetails-module--background-missing-image--db5b3";
export var bannerDescription = "ServicesDetails-module--banner-description--b1408";
export var cardImage = "ServicesDetails-module--card-image--a7dd2";
export var controls = "ServicesDetails-module--controls--f56d8";
export var disabled = "ServicesDetails-module--disabled--cf770";
export var infos = "ServicesDetails-module--infos--d9d2b";
export var item = "ServicesDetails-module--item--571e9";
export var mobileGallery = "ServicesDetails-module--mobile-gallery--dbc68";
export var oneImage = "ServicesDetails-module--one-image--684d4";
export var serviceBanner = "ServicesDetails-module--service-banner--04dcb";
export var serviceDescription = "ServicesDetails-module--service-description--ceed1";
export var serviceDetails = "ServicesDetails-module--service-details--c9215";
export var serviceDetailsContent = "ServicesDetails-module--service-details-content--42f3d";
export var serviceMobileMenu = "ServicesDetails-module--service-mobile-menu--3e47d";
export var serviceWrapper = "ServicesDetails-module--service-wrapper--6166d";
export var servicesDetailsContainer = "ServicesDetails-module--services-details-container--a7bed";
export var shareWhatsApp = "ServicesDetails-module--shareWhatsApp--1b54e";
export var shareWrapper = "ServicesDetails-module--share-wrapper--ea273";
export var slick = "ServicesDetails-module--slick--6e8c0";
export var sliderWrapper = "ServicesDetails-module--slider-wrapper--7d9c5";
export var titleShareWhatsApp = "ServicesDetails-module--titleShareWhatsApp--6e535";
export var twoImages = "ServicesDetails-module--two-images--238bc";