import React, { useState } from 'react'
import ReactLightbox from './Lightbox'
import cn from 'classnames'

import ContactFormAside from './ContactFormAside'

import * as st from '~/assets/styl/Details.module.styl'
import { LazyImage } from 'react-lazy-images'
import SoldOutTag from './SoldOutTag'

type Props = {
  pageContext: {
    image: string
    id: number
    name: string
    region: string
    return_at?: string
    return_content?: string
    exit_at?: string
    exit_content?: string
    schedule: string
    included: string
    not_included: string
    important: string
    images: { original: string; thumb: string; alt?: string }[]
    unavailable: boolean
    roadmapFile: string
  }
}

export default function Details({
  pageContext: {
    image,
    id,
    name,
    region,
    return_at,
    return_content,
    exit_at,
    exit_content,
    schedule,
    included,
    not_included,
    important,
    unavailable,
    images,
    roadmapFile,
  },
}: Props) {
  const [imagesZoom, setImagesZoom] = useState<Array<string>>()
  const [index, setIndex] = useState(0)

  const roadmapDownload = () => {
    const link = document.createElement('a')
    link.href = roadmapFile
    link.target = '_blank'
    link.click()
  }

  return (
    <div className={st.detailsContainer}>
      <div className={st.detailsWrapper}>
        <section
          className={cn(st.detailsBanner, {
            [st.backgroundMissingImage]: !image,
          })}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h2>
            {unavailable && <SoldOutTag />}
            <span>
              <b>{name}</b>
            </span>
            <span>{region}</span>
          </h2>
        </section>
        <div className={st.cardsWrapper}>
          <div className={cn(st.departing, { [st.fullWidthCard]: !return_at })}>
            <span className="icon-card-right-arrow"></span>
            <p className={st.cardHeader}>Saída</p>
            <p>Data: {exit_at}</p>
            {exit_content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: exit_content,
                }}
              ></div>
            )}
          </div>
          {return_at && (
            <div className={st.returning}>
              <span className="icon-card-left-arrow"></span>
              <p className={st.cardHeader}>Retorno</p>
              <p>Data: {return_at}</p>
              {return_content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: return_content,
                  }}
                ></div>
              )}
            </div>
          )}
        </div>
        {schedule && (
          <div className={st.schedule}>
            <aside>
              <span>Programação</span>
            </aside>
            <div
              className={st.scheduleDetails}
              dangerouslySetInnerHTML={{
                __html: schedule,
              }}
            ></div>
          </div>
        )}
        {included && (
          <div className={st.include}>
            <aside>
              <span>Incluso no pacote</span>
            </aside>
            <div
              className={st.includeDetails}
              dangerouslySetInnerHTML={{
                __html: included,
              }}
            ></div>
          </div>
        )}
        {not_included && (
          <div className={st.notInclude}>
            <aside>
              <span>Não está incluso no pacote</span>
            </aside>
            <div
              className={st.notIncludeDetails}
              dangerouslySetInnerHTML={{
                __html: not_included,
              }}
            ></div>
          </div>
        )}
        {roadmapFile && (
          <div className={st.info}>
            <aside>
              <span>Roteiro do pacote</span>
            </aside>
            <div className={st.infoContainer}>
              {!important && (
                <div className={st.infoDetails}>
                  <p>
                    Acesse rapidamente o roteiro completo da sua viagem! Clique
                    no botão abaixo para fazer o download e tenha todas as
                    informações essenciais sempre à mão.
                  </p>
                </div>
              )}
              {important && (
                <div
                  className={st.infoDetails}
                  dangerouslySetInnerHTML={{
                    __html: important,
                  }}
                ></div>
              )}
              {roadmapFile && (
                <button
                  type="button"
                  title="Baixar roteiro"
                  aria-label="Baixar roteiro"
                  onClick={roadmapDownload}
                >
                  Baixar roteiro
                </button>
              )}
            </div>
          </div>
        )}
        {images?.length > 0 && (
          <div className={st.gallery}>
            <aside>
              <span>Galeria de imagens</span>
            </aside>
            <div className={st.galleryImages}>
              {images.map((item, index) => (
                <div
                  className={st.cardImage}
                  onClick={() => {
                    setIndex(index)
                    setImagesZoom(images.map((i) => i.original))
                  }}
                  key={index}
                >
                  <LazyImage
                    src={item.thumb}
                    alt={item.alt}
                    placeholder={({ imageProps, ref }) => (
                      <img ref={ref} src={item.thumb} alt={item.alt} />
                    )}
                    actual={({ imageProps }) => (
                      <img {...imageProps} style={{ width: '100%' }} />
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <ContactFormAside itemId={id} unavailable={unavailable} />
      <ReactLightbox
        index={index}
        images={imagesZoom}
        setIndex={setIndex}
        setImages={setImagesZoom}
      />
    </div>
  )
}
