// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "ShoppingPackages-module--background-missing-image--30f24";
export var departing = "ShoppingPackages-module--departing--74163";
export var descriptions = "ShoppingPackages-module--descriptions--58b95";
export var destination = "ShoppingPackages-module--destination--d2fa2";
export var installmentBar = "ShoppingPackages-module--installment-bar--5e37a";
export var otherPackages = "ShoppingPackages-module--other-packages--12492";
export var returning = "ShoppingPackages-module--returning--c3cbc";
export var shoppingCard = "ShoppingPackages-module--shopping-card--a4101";
export var shoppingCardBody = "ShoppingPackages-module--shopping-card-body--3fe0a";
export var shoppingCardHeader = "ShoppingPackages-module--shopping-card-header--bc72c";
export var shoppingCardhover = "ShoppingPackages-module--shopping-cardhover--84cec";
export var shoppingCardsWrapper = "ShoppingPackages-module--shopping-cards-wrapper--3311f";
export var shoppingHeader = "ShoppingPackages-module--shopping-header--d68df";
export var shoppingMobileCardsWrapper = "ShoppingPackages-module--shopping-mobile-cards-wrapper--26069";
export var shoppingPacksContainer = "ShoppingPackages-module--shopping-packs-container--10c26";