// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "TravelOptions-module--background-missing-image--b5441";
export var containerImages = "TravelOptions-module--container-images--1573f";
export var departing = "TravelOptions-module--departing--d1dcb";
export var descriptions = "TravelOptions-module--descriptions--d0e88";
export var destination = "TravelOptions-module--destination--d999d";
export var installmentBar = "TravelOptions-module--installment-bar--57837";
export var loadMore = "TravelOptions-module--load-more--78541";
export var returning = "TravelOptions-module--returning--1a5c0";
export var tags = "TravelOptions-module--tags--c2493";
export var travelCard = "TravelOptions-module--travel-card--3e7d6";
export var travelCardBody = "TravelOptions-module--travel-card-body--26e7c";
export var travelCardHeader = "TravelOptions-module--travel-card-header--b808d";
export var travelCardsWrapper = "TravelOptions-module--travel-cards-wrapper--90d32";
export var travelOptionsContainer = "TravelOptions-module--travel-options-container--2e644";