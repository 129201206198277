// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "TravelPackages-module--background-missing-image--9a443";
export var containerImages = "TravelPackages-module--container-images--55ae4";
export var departing = "TravelPackages-module--departing--a4436";
export var descriptions = "TravelPackages-module--descriptions--6895f";
export var destination = "TravelPackages-module--destination--3de92";
export var installmentBar = "TravelPackages-module--installment-bar--a6ab1";
export var otherPackages = "TravelPackages-module--other-packages--36b76";
export var returning = "TravelPackages-module--returning--72ef4";
export var tags = "TravelPackages-module--tags--dfc70";
export var travelCard = "TravelPackages-module--travel-card--c89e7";
export var travelCardBody = "TravelPackages-module--travel-card-body--f016d";
export var travelCardHeader = "TravelPackages-module--travel-card-header--59bf9";
export var travelCardsWrapper = "TravelPackages-module--travel-cards-wrapper--c9783";
export var travelHeader = "TravelPackages-module--travel-header--17a65";
export var travelMobileCardsWrapper = "TravelPackages-module--travel-mobile-cards-wrapper--71aac";
export var travelPacksContainer = "TravelPackages-module--travel-packs-container--bf18d";