// extracted by mini-css-extract-plugin
export var bottomFooter = "Footer-module--bottom-footer--b7eaa";
export var footerContainer = "Footer-module--footer-container--713e1";
export var iconAirplane = "Footer-module--icon-airplane--aed2a";
export var iconBag = "Footer-module--icon-bag--6ec03";
export var iconBus = "Footer-module--icon-bus--34516";
export var iconCar = "Footer-module--icon-car--10eed";
export var iconCard = "Footer-module--icon-card--5e55e";
export var iconCardLeftArrow = "Footer-module--icon-card-left-arrow--8ffc8";
export var iconCardRightArrow = "Footer-module--icon-card-right-arrow--46822";
export var iconCase = "Footer-module--icon-case--da002";
export var iconCase2 = "Footer-module--icon-case2--2f242";
export var iconClose = "Footer-module--icon-close--18a61";
export var iconEarth = "Footer-module--icon-earth--b8be5";
export var iconFacebook = "Footer-module--icon-facebook--d5bc2";
export var iconFavStar = "Footer-module--icon-fav-star--d36bd";
export var iconGlobe = "Footer-module--icon-globe--4dee6";
export var iconHeart = "Footer-module--icon-heart--3f6b5";
export var iconHotel = "Footer-module--icon-hotel--3a6c7";
export var iconHouse = "Footer-module--icon-house--5088b";
export var iconInstagram = "Footer-module--icon-instagram--f3c41";
export var iconLeftArrow = "Footer-module--icon-left-arrow--d3440";
export var iconLeftControl = "Footer-module--icon-left-control--32e1b";
export var iconLinkedin = "Footer-module--icon-linkedin--c89f8";
export var iconMessage = "Footer-module--icon-message--6c70d";
export var iconMug = "Footer-module--icon-mug--761ab";
export var iconRightArrow = "Footer-module--icon-right-arrow--27a46";
export var iconRightControl = "Footer-module--icon-right-control--05e40";
export var iconSearch = "Footer-module--icon-search--3fa25";
export var iconShip = "Footer-module--icon-ship--65c5e";
export var iconShirt = "Footer-module--icon-shirt--52af1";
export var iconSofa = "Footer-module--icon-sofa--2f9f7";
export var iconTwitter = "Footer-module--icon-twitter--788a9";
export var iconWhatsapp = "Footer-module--icon-whatsapp--7809d";
export var imageLocation = "Footer-module--image-location--809a0";
export var location = "Footer-module--location--a1f16";
export var menuItem = "Footer-module--menu-item--028db";
export var navContainer = "Footer-module--nav-container--6950b";
export var pages = "Footer-module--pages--c9845";
export var phone = "Footer-module--phone--ae75b";
export var social = "Footer-module--social--5573a";
export var subItemsMenu = "Footer-module--sub-items-menu--2f896";
export var tikiLogo = "Footer-module--tiki-logo--ed22e";
export var upperFooter = "Footer-module--upper-footer--f45a6";